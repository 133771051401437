import React from 'react'
import '../assets/css/style.css'
import { Circles } from 'react-loader-spinner'
const Loader = () => {
    return (
        <div className='spinner_loader'>
            <Circles
                height="80"
                width="80"
                color="#ffc702"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default Loader