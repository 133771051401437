import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { ProfileProvider } from './Context/ProfileContext';
import Loader from './components/Loader';
import { VideoProvider } from './Context/VideoContext';
import { NavbarProvider } from './Context/NavbarContext';
import { HTMLProvider } from './Context/HtmlContext';
import axiosBaseURL from './baseUrl';

import PrivateRoutes from './utils/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Support from './pages/Support';
import Setting from './pages/Setting';
import Pricing from './pages/Pricing';
import StoryPreview from './pages/StoryPreview';
import { DragProvider } from './Context/DragContext';
import { ApiProvider } from './Context/ApiContext';


const SignupPage = React.lazy(() => import('./pages/Signup'));
const ForgetPassword = React.lazy(() => import('./pages/ForgetPassword'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const LoginPage = React.lazy(() => import('./pages/Login'));
const AdminRoutes = React.lazy(() => import('./utils/AdminRoute'));
const VideoRoute = React.lazy(() => import('./utils/VideoRoute'));
const PublishRoute = React.lazy(() => import('./utils/PublishRoute'));
const AuthenticateRoute = React.lazy(() => import('./utils/AuthenticateRoute'));
const AdminDashboard = React.lazy(() => import('./pages/AdminDashboard'));
const Landingpage = React.lazy(() => import('./pages/Landingpage'));
// const ChooseVideoStyle = React.lazy(() => import('./pages/ChooseVideoStyle'));
const VideoCreation = React.lazy(() => import('./pages/VideoCreation'));
const Publish = React.lazy(() => import('./pages/Publish'));
const MediaLibrary = React.lazy(() => import('./pages/MediaLibaray'));

function App() {
  const [Authorization, setAuthorization] = useState(false);

  const fetchUserProfile = useCallback(async (token) => {
    try {
      const response = await axiosBaseURL.get("/authorization-status", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.ok) {
        setAuthorization(true);
      } else {
        setAuthorization(false);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("tippen_access_token");
    if (token && !Authorization) {
      fetchUserProfile(token);
    }
  }, [Authorization, fetchUserProfile]); 

  return (
    <>
      <NavbarProvider>
        <ApiProvider>
          <DragProvider>
            <HTMLProvider>
              <VideoProvider>
                <ProfileProvider>
                  <Suspense fallback={<Loader />}>
                    <BrowserRouter>
                      <Routes>
                        <Route path="/reset-password" element={<ResetPassword />} exact />
                        <Route element={<PrivateRoutes />}>
                          <Route path="/dashboard" element={<Dashboard />} exact />
                          <Route path="/media-library" element={<MediaLibrary />} exact />
                          <Route path="/pricing" element={<Pricing />} exact />
                          <Route path="/support" element={<Support />} exact />
                          <Route path="/setting" element={<Setting />} exact />
                        </Route>
                        <Route element={<VideoRoute />}>
                          {/* <Route path="/choose-style" element={<ChooseVideoStyle />} exact /> */}
                          <Route path="/story-creation" element={<VideoCreation />} exact />
                          <Route path="/publish" element={<Publish />} exact />
                        </Route>
                        <Route element={<PublishRoute />}>
                          <Route path="/preview" element={<StoryPreview />} exact />
                        </Route>
                        <Route element={<AdminRoutes />}>
                          <Route path="/admin" element={<AdminDashboard />} exact />
                          <Route path="/admin-setting" element={<Setting />} exact />
                        </Route>
                        <Route element={<AuthenticateRoute />}>
                          <Route path="/signup" element={<SignupPage />} exact />
                          <Route path="/" element={<LoginPage />} />
                          <Route path="/forget-password" element={<ForgetPassword />} />
                        </Route>
                        <Route path="*" element={<Landingpage />} />
                      </Routes>
                    </BrowserRouter>
                  </Suspense>
                </ProfileProvider >
              </VideoProvider >
            </HTMLProvider>
          </DragProvider >
        </ApiProvider>
      </NavbarProvider>
    </>
  );
}

export default App;
