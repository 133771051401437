import React from 'react'
import SettingScreen from '../components/Setting'

const Setting = () => {
    return (
        <div className="min-h-full  py-12 px-4 sm:px-6 lg:px-8 fadeIn">
            <div className=" w-full space-y-8" >
                <SettingScreen />
            </div>
        </div>

    )
}

export default Setting