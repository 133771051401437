import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
const ApiContext = createContext();

import axiosBaseURL from '../baseUrl';
export const useApi = () => {
    return useContext(ApiContext);
};

export const ApiProvider = ({ children }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [htmlResponse, setHtmlResponse] = useState('');

    const token = localStorage.getItem("tippen_access_token");
    const sendVideoToServer = async (id, videoData, title, subTitle, buttonText, contact_us_button_text, titlePosition, SubtitlePosition, buttonPosition, titlePosition1, SubtitlePosition1, buttonPosition1, button2Position, videoCover, WebUrl, actionType, title1, subTitle1, contact_us_button_text1,buttonTextUrl) => {
        /**
         * @typedef {Object} VideoUrlObject
         * @property {any} video1
         * @property {any} [video2]
         */
        try {
            const response = await axiosBaseURL.post("/store-video", {
                'id': id,
                'video': videoData,
                'heading': title,
                'subheading': subTitle,
                'buttonText': buttonText,
                'heading1': title1,
                'subheading1': subTitle1,
                'contact_us_button_text1': contact_us_button_text1,
                'contact_us_button_text': contact_us_button_text,
                'titlePosition': titlePosition,
                'SubtitlePosition': SubtitlePosition,
                'buttonPosition': buttonPosition,
                'titlePosition1': titlePosition1,
                'SubtitlePosition1': SubtitlePosition1,
                'buttonPosition1': buttonPosition1,
                'button2Position': button2Position,
                'video2': videoCover,
                'WebUrl': WebUrl,
                'actionType': actionType,
                'buttonTextUrl': buttonTextUrl,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                /** @type {VideoUrlObject} */
                const videoUrlObject = {
                    video1: response.data.result.video_path_1,
                    html_id: response.data.result.html_id
                };
                if (actionType === 'nextVideo') {
                    videoUrlObject.video2 = response.data.result.video_path_2;
                }
                setVideoUrl(videoUrlObject);
            }
            else {
                throw new Error('Failed to upload video. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error sending video to server:', error);
        }
    };

    const sendHtmlToAnotherApi = async (id, htmlData, receiverEmail, videoName) => {
        try {
            const response = await axiosBaseURL.post("/store-html",
                {
                    'id': id,
                    'html': htmlData,
                    'tippen_receiverEmail': receiverEmail,
                    'title': videoName,
                }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.status === 200) {
                setHtmlResponse(response.data.result.iframe);
            }
            else {
                throw new Error('Failed to upload video. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error sending video to server:', error);
        }
    };

    return (
        <ApiContext.Provider value={{ videoUrl, setVideoUrl, htmlResponse, loading, setLoading, setHtmlResponse, sendVideoToServer, sendHtmlToAnotherApi }}>
            {children}
        </ApiContext.Provider>
    );
};
ApiProvider.propTypes = {
    children: PropTypes.node.isRequired,
};