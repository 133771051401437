import React, { useContext } from 'react'
import { Fragment } from 'react'
import mainLogo from '../assets/images/Vector.svg';
import mainPeople from '../assets/images/avatar.jpg';
import toast from 'react-hot-toast';
import axiosBaseURL from '../baseUrl';
import { Link, useNavigate } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react'
import PropTypes from 'prop-types';
import { VideoContext } from '../Context/VideoContext';
import { useNavbarContext } from '../Context/NavbarContext';
import { useDragContext } from '../Context/DragContext';
import ProfileContext from '../Context/ProfileContext';
import { useApi } from '../Context/ApiContext';
function Navbar({ isAdmin, sendVideo }) {
    const { selectedVideo, settings, screen2Settings } = useContext(VideoContext);
    const { dragValues } = useDragContext();
    const { id, title, subTitle, buttonText, receiverEmail, videoCover, WebUrl, actionType, contact_us_button_text, buttonTextUrl } = settings;
    const { displayCreateButton, buttons } = useNavbarContext();
    const { profilePhoto } = useContext(ProfileContext);
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const { sendVideoToServer, setLoading } = useApi();
    let navigate = useNavigate();

    const handleButtonClick = async (link, isfunction, back) => {
        if (sendVideo && back == false) {
            try {
                setLoading(true);
                await sendVideoToServer(id, selectedVideo, title, subTitle, buttonText, contact_us_button_text, dragValues.title, dragValues.subTitle, dragValues.button, dragValues.title1, dragValues.subTitle1, dragValues.button1, dragValues.button2, videoCover, WebUrl, actionType, screen2Settings.title, screen2Settings.subTitle, screen2Settings.contact_us_button_text, buttonTextUrl);
            } catch (error) {
                console.error('Error:', error);
            }
            return;
        }
        else if (isfunction) {
            toast.dismiss();
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!receiverEmail || !emailRegex.test(receiverEmail)) {
                toast.error('Please enter a valid email');
                return;
            } else if (!selectedVideo) {
                toast.error('Please select the video first');
                return;
            } else if (actionType === 'openUrl') {
                if (!WebUrl) {
                    toast.error('Please enter the URL');
                    return;
                }

                try {
                    const newUrl = new URL(WebUrl);
                    if (newUrl.protocol !== 'http:' && newUrl.protocol !== 'https:') {
                        throw new Error('Invalid protocol');
                    }
                } catch (err) {
                    toast.error('Please enter a valid URL');
                    return;
                }
            } else if (actionType === 'nextVideo') {
                if (videoCover == '') {
                    toast.error('Please select the Second video first');
                    return;
                }
            }
        }
        navigate(link);

    };
    const Logout = () => {
        toast.dismiss();
        toast.promise(
            authenticateUser(),
            {
                loading: 'Loading',
                success: (response) => {
                    if (response.data.status === 200) {
                        localStorage.removeItem('profile_photo');
                        localStorage.removeItem('tippen_access_token');
                        localStorage.removeItem('is_admin');
                        setTimeout(() => {
                            toast.dismiss();
                            navigate('/');
                        }, 1000);
                        const message = response.data.message.join('\n');
                        return message;
                    } else {
                        const errorMessage = response.data.message.join('\n');
                        throw new Error(errorMessage);

                    }
                },
                error: (error) => {
                    return error.message;
                }
            }
        );
    }
    const token = localStorage.getItem("tippen_access_token");
    const authenticateUser = () => {
        return axiosBaseURL.post("/logout", {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }
    return (
        <div className="tippen_App">
            <header className="tippen_App_header">
                <div className='header_logo_div'>
                    <Link to={isAdmin ? '/admin' : '/dashboard'}>
                        <img className='tippen_logo' src={mainLogo} alt="Tippen" />
                    </Link>
                </div>
                <div className='header_button_div'>
                    <ul className='header_button_list'>
                        {displayCreateButton && (
                            buttons.map((button, index) => (
                                <li key={index}>
                                    <button onClick={() => handleButtonClick(button.link, button.function, button.back)} className="create_video_button">{button.name}</button>
                                </li>
                            ))
                        )}
                        <li>
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="header_dropdown_button" id="menu-button" >
                                        {profilePhoto.profilePhoto ? <img src={profilePhoto.profilePhoto} alt="userIcon" width='56' height='56' /> : <img src={mainPeople} alt="userIcon" width='56' height='56' />}
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        type="button" onClick={Logout}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block w-full px-4 py-2 text-center text-sm flex justify-center items-center gap-3'
                                                        )}
                                                    >
                                                        <i className="fa fa-sign-out relative" style={{ top: '1px' }}></i> Sign out
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </li>
                    </ul>
                </div>
            </header >
        </div >
    );
}
export default Navbar;
Navbar.propTypes = {
    Display: PropTypes.bool,
    isAdmin: PropTypes.bool,
    sendVideo: PropTypes.bool,
};