import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [settings, setSettings] = useState({
        videoName: 'Video Title',
        title: 'Title',
        subTitle: 'Sub Title',
        buttonText: 'Button 2',
        contact_us_button_text: 'Button 1',
        receiverEmail: '',
        actionType: 'openUrl',
        buttonTextUrl: 'https://apps.tippen.com.au/',
        WebUrl: '',
        videoCover: '',
    });
    const [screen2Settings, setScreen2Settings] = useState({
        title: 'Title',
        subTitle: 'Sub Title',
        contact_us_button_text: 'Contact us',
    });
    const updateSelectedVideo = (video) => {
        setSelectedVideo(video);
    };

    const updateSettings = (newSettings) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            ...newSettings,
        }));
    };
    const updateSettings2 = (newSettings) => {
        setScreen2Settings((prevSettings) => ({
            ...prevSettings,
            ...newSettings,
        }));
    };

    return (
        <VideoContext.Provider value={{ screen2Settings, setScreen2Settings, updateSettings2, selectedVideo, setSelectedVideo, updateSelectedVideo, settings, updateSettings, setSettings }}>
            {children}
        </VideoContext.Provider>
    );
};

VideoProvider.propTypes = {
    children: PropTypes.node,
};
