/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import axiosBaseURL from '../baseUrl';
import DefaultImage from '../assets/images/avatar.jpg';
const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
    const [profilePhoto, setProfilePhoto] = useState({
        profilePhoto: '',
        name: '',
        email: '',
        cssLink: '',
        jsLink: ''
    });

    const setProfilePhotoUrl = (photoUrl) => {
        setProfilePhoto(photoUrl);
    };

    const token = localStorage.getItem("tippen_access_token");

    const fetchProfilePhoto = useCallback(async () => {
        try {
            if (token) {
                const response = await axiosBaseURL.get("/my-profile", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { profile_photo_path, name, email, css, js } = response.data.result;
                setProfilePhotoUrl({
                    profilePhoto: profile_photo_path,
                    name,
                    email,
                    cssLink: css,
                    jsLink: js
                });
            }
        } catch (error) {
            setProfilePhotoUrl({
                profilePhoto: DefaultImage,
                name: '',
                email: ''
            });
        }
    }, [token]);

    useEffect(() => {
        fetchProfilePhoto();
    }, [fetchProfilePhoto, token]);


    return (
        <ProfileContext.Provider value={{ profilePhoto, setProfilePhotoUrl, fetchProfilePhoto }}>
            {children}
        </ProfileContext.Provider>
    );
};

ProfileProvider.propTypes = {
    children: PropTypes.node,
};

export default ProfileContext;
