import axios from 'axios';
import toast from 'react-hot-toast';

const axiosInstance = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('tippen_access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return handleResponse(response);
    },
    error => {
        return handleError(error);
    }
);

const handleStatus = (status, data) => {
    switch (status) {
        case 200:
            return data;
        case 201:
            return data;
        case 400:
            toast.error('Bad request');
            break;
        case 401:
            localStorage.removeItem('is_admin');
            localStorage.removeItem('profile_photo');
            localStorage.removeItem('tippen_access_token');
            toast.error(data.statusText);
            setTimeout(() => {
                window.location.href = '/';
            }, 800);
            break;
        case 404:
            toast.error('Resource not found');
            break;
        default:
            toast.error('Something went wrong');
            break;
    }
    return null;
};

const handleResponse = response => {
    return handleStatus(response.status, response);
};

const handleError = error => {
    if (error.response) {
        return handleStatus(error.response.status, error.response);
    } else {
        console.error('Error:', error);
        toast.error('Network error');
        return Promise.reject(error);
    }
};

export default axiosInstance;
