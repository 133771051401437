import React from 'react'
import DashboardScreen from '../components/DashboardScreen'

const Dashboard = () => {
  return (
    <div className='fadeIn'>
      <DashboardScreen />
    </div>
  )
}

export default Dashboard