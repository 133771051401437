
import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import axiosBaseURL from '../baseUrl';
import toast from 'react-hot-toast';
import { VideoContext } from '../Context/VideoContext';
// Get today's date
const today = new Date();
// Calculate previous and next dates
const previousDate = new Date(today);
previousDate.setDate(today.getDate() - 1); // Previous date
const nextDate = new Date(today);
nextDate.setDate(today.getDate() + 1); // Next date

// Format dates as ISO strings for consistency
const formatDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
// Dummy data for series (Number of views)
const dummySeriesData = [
    { date: formatDate(previousDate), video_watch: null },
    { date: formatDate(today), video_watch: null },
    { date: formatDate(nextDate), video_watch: null },
    // Add more data as needed
];

// Dummy data for series1 (Number of Schedule)
const dummySeries1Data = [
    { date: formatDate(previousDate), schedule_call: null },
    { date: formatDate(today), schedule_call: null },
    { date: formatDate(nextDate), schedule_call: null },
    // Add more data as needed
];

const DashboardScreen = () => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const { setSettings, setScreen2Settings } = useContext(VideoContext);
    const [toasterData, setToasterData] = useState(false);

    useEffect(() => {
        setSettings({
            videoName: 'Video Title',
            title: 'Title',
            subTitle: 'Sub Title',
            buttonText: 'Help',
            contact_us_button_text: 'Contact us',
            receiverEmail: '',
            actionType: 'openUrl',
            WebUrl: '',
            videoCover: '',
        });
        setScreen2Settings({
            title: 'Title',
            subTitle: 'Sub Title',
            contact_us_button_text: 'Contact us',
        });
        getSaleDataFunction()
        // Cleanup function when component unmounts or before it rerenders
        return () => {
            setToasterData(false); // Set toasterData to false when leaving the page
        };
    }, [selectedPeriod])
    // Function to restructure and convert timestamps
    function restructureData(data) {
        const chartData = {};
        const chartData1 = {};
    
        // Process sale_data for views and schedule calls
        data.forEach(item => {
            item.sale_data.forEach(sale => {
                const date = sale.updated_at;
                if (sale.video_watch !== 0) {
                    if (chartData[date]) {
                        chartData[date] += sale.video_watch;
                    } else {
                        chartData[date] = sale.video_watch;
                    }
                }
                if (sale.schedule_call !== 0) {
                    if (chartData1[date]) {
                        chartData1[date] += sale.schedule_call;
                    } else {
                        chartData1[date] = sale.schedule_call;
                    }
                }
            });
        });
    
        // Transform aggregated data into chart format
        const series = [{
            name: 'Number of views',
            data: Object.entries(chartData).map(([date, value]) => [date, value])
        }];
    
        const series1 = [{
            name: 'Number of Schedule',
            data: Object.entries(chartData1).map(([date, value]) => [date, value])
        }];
    
        // Prepare combined chart data if needed
        const series2 = [{
            contacted_users: Object.entries(chartData1).map(([date, value]) => [date, value]),
            number_of_views: Object.entries(chartData).map(([date, value]) => [date, value]),
        }];
    
        // Update state or perform further actions with the chart data
        if (series[0].data.length > 0) {
            setSeries(series);
        }
        if (series1[0].data.length > 0) {
            setSeries1(series1);
        }
        setSeries2(series2); // Assuming setSeries2 handles combined data if needed
    }
    

    const getSaleDataFunction = async () => {
        try {
            // Fetch sale data
            const response = await getSaleData();

            // Check response status
            if (response.data.status === 200) {
                // Restructure data
                if (toasterData) {
                    toast.dismiss();
                    if (response.data.result.length == 0) {
                        toast.success('No record found');
                    }
                }
                restructureData(response.data.result);
            } else {
                const errorMessage = response.data.message.join('\n');
                toast.error(errorMessage);
            }
        } catch (error) {
            // Handle any errors from axios or processing
            toast.error(error.message);
        }
    };


    const getSaleData = () => {
        return axiosBaseURL.post("/get-sale-data", {
            'filter': selectedPeriod,
        });
    }
    const handlePeriodChange = (period) => {
        setToasterData(true);
        setSelectedPeriod(period);
    };
    // eslint-disable-next-line no-unused-vars
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'line',
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#05B380']
        },
        markers: {
            size: 5 // Adjust marker size as needed
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            }
        },
        xaxis: {
            type: 'datetime',
            format: 'dd MMM yyyy'
        },
        yaxis: {
            type: 'integer',
        }
    });

    // eslint-disable-next-line no-unused-vars
    const [series, setSeries] = useState([
        {
            name: 'Number of views',
            data: dummySeriesData.map(item => [item.date, item.video_watch])
        }
    ]);
    const [series2, setSeries2] = useState(null);

    const [series1, setSeries1] = useState([
        {
            name: 'Number of Schedule',
            data: dummySeries1Data.map(item => [item.date, item.schedule_call])
        }
    ]);
    const handleExportCSV = () => {
        if (series2 == null) {
            toast.warning('No Data available');
        }
        console.log(series2[0]['contacted_users']);
        const csvContent = convertSeriesToCSV(series2[0]['contacted_users'], series2[0]['number_of_views']);
        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "series2.csv");
        document.body.appendChild(link);
        link.click();
    };
    function convertSeriesToCSV(chartData, chartData1) {
        if (chartData.length === 0 && chartData1.length === 0) {
            return '';
        }

        // Merge data based on date
        const mergedData = {};
        chartData.forEach(([date, views]) => {
            // Create a Date object from the date string
            let localDate = new Date(date);

            // Get local date and time components
            let localDateString = localDate.toLocaleDateString(); // Date portion in local format

            if (!mergedData[localDateString]) {
                mergedData[localDateString] = { date: localDateString, video_watched: views, contacted_users: 0 };
            } else {
                mergedData[localDateString].video_watched = views;
            }
        });

        chartData1.forEach(([date, users]) => {
            // Create a Date object from the date string
            let localDate = new Date(date);

            // Get local date and time components
            let localDateString = localDate.toLocaleDateString(); // Date portion in local format

            if (!mergedData[localDateString]) {
                mergedData[localDateString] = { date: localDateString, video_watched: 0, contacted_users: users };
            } else {
                mergedData[localDateString].contacted_users = users;
            }
        });
        // Convert merged data to CSV
        let csvContent = 'Date(M/D/Y),Contacted Users,Video Watched\n';
        Object.values(mergedData).forEach(({ date, video_watched, contacted_users }) => {
            csvContent += `${date},${video_watched},${contacted_users}\n`;
        });

        return csvContent;
    }

    return (
        <div className='dashboard_main_class'>
            <div className='dashboard_head'>
                <div className='dashboard_main_heading_div'>
                    <h1 className='dashboard_main_heading'>Analytics</h1>
                </div>
                <div className='dashboard_options'>
                    <Menu as="div" className="relative inline-block text-left option_div_common_class_upper">
                        <div>
                            <Menu.Button className="option_select inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900  hover:bg-gray-500">
                                <i className="fa fa-calendar"></i>
                                {selectedPeriod === 0 && 'Last 30 Days'}
                                {selectedPeriod === 1 && 'Last 6 Months'}
                                {selectedPeriod === 2 && 'Last 1 Year'}
                                {selectedPeriod === 3 && 'All Time'}
                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items style={{ top: '30px' }} className="absolute left-0 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <h1
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                                onClick={() => handlePeriodChange(0)}
                                            >
                                                Last 30 Days
                                            </h1>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <h1
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                                onClick={() => handlePeriodChange(1)}
                                            >
                                                Last 6 Months
                                            </h1>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <h1
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                                onClick={() => handlePeriodChange(2)}
                                            >
                                                Last 1 Year
                                            </h1>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <h1
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                                onClick={() => handlePeriodChange(3)}
                                            >
                                                All time
                                            </h1>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <div className='option_button_div option_div_common_class'>
                        <button onClick={handleExportCSV} className='option_button'>
                            <i className="fa fa-cloud-download"></i> Export
                        </button>
                    </div>

                </div>
            </div>
            <div className='dashboard_graphs_main_div mb-5  '>
                <div className='side_statistics_panel'>
                    <div style={{ backgroundColor: '#111111', color: 'white' }} className='block max-w-sm p-6 bg-white border border-white-200 rounded-lg shadow hover:bg-white-100 dark:bg-white-800 dark:border-white-700 dark:hover:bg-white-700'>
                        <div className='cardHeader'>
                            <h1 className='Main_heading'>Video sales</h1>
                            {/* <h1 className='green_h'>View report</h1> */}
                        </div>
                        <div className='video_sale_circle'>
                            Coming Soon.....</div>
                    </div>
                </div>
                <div className='right_charts_panel'>
                    <div className='rcp_col rcp_col_1'>
                        <div style={{ width: '100%' }} className='block  p-6 bg-white border border-white-200 rounded-lg shadow hover:bg-white-100 dark:bg-white-800 dark:border-white-700 dark:hover:bg-white-700'>
                            <div className='cardHeader'>
                                <h1 className='Main_heading'>Number of views</h1>
                                {/* <h1 className='green_h'>View report</h1> */}
                            </div>
                            {/* <div>
                                <h1 className='sales_percentage'>12.46%</h1>
                            </div> */}
                            <div id="chart">
                                <ReactApexChart options={chartOptions} series={series} type="line" height={400} />
                            </div>
                        </div>

                    </div>
                    <div className='rcp_col rcp_col_1'>
                        <div style={{ width: '100%' }} className='block p-6 bg-white border border-white-200 rounded-lg shadow hover:bg-white-100 dark:bg-white-800 dark:border-white-700 dark:hover:bg-white-700'>
                            <div className='cardHeader'>
                                <h1 className='Main_heading'>Contacted Users</h1>
                                {/* <h1 className='green_h'>View report</h1> */}
                            </div>
                            {/* <div>
                                <h1 className='sales_percentage'>12.46%</h1>
                            </div> */}
                            <div id="chart">
                                <ReactApexChart options={chartOptions} series={series1} type="line" height={400} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DashboardScreen;
