import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const NavbarContext = createContext();

export const useNavbarContext = () => useContext(NavbarContext);

export const NavbarProvider = ({ children }) => {
  const [displayCreateButton, setDisplayCreateButton] = useState(true);
  const [buttons, setButtons] = useState([]);

  const addNewButton = (buttonData) => {
    setButtons(buttonData); 
  };

  return (
    <NavbarContext.Provider value={{ displayCreateButton, setDisplayCreateButton, buttons, addNewButton }}>
      {children}
    </NavbarContext.Provider>
  );
};

NavbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavbarContext;
