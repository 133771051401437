import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
const DragContext = createContext();

export const DragProvider = ({ children }) => {
    const [dragValues, setDragValues] = useState({
        button: { x: 0, y: 0 },
        button1: { x: 0, y: 0 },
        button2: { x: 0, y: 0 },
        subTitle: { x: 0, y: 0 },
        title: { x: 0, y: 0 },
        subTitle1: { x: 0, y: 0 },
        title1: { x: 0, y: 0 }
    });

    const updateDragValues = (type, values) => {
        setDragValues(prevState => ({
            ...prevState,
            [type]: values,
        }));
        console.log(dragValues);
    };

    return (
        <DragContext.Provider value={{ dragValues, updateDragValues }}>
            {children}
        </DragContext.Provider>
    );
};
DragProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useDragContext = () => useContext(DragContext);
