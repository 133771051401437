import React, { useContext, useEffect, useState } from 'react'
import { Outlet, Navigate } from "react-router-dom";
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import '../assets/css/style.css';
import { useNavbarContext } from '../Context/NavbarContext';
import { VideoContext } from '../Context/VideoContext';
import { useHTMLContext } from '../Context/HtmlContext';
import { useApi } from '../Context/ApiContext';
import  toast,{ Toaster } from 'react-hot-toast';
const PrivateRoute = () => {
    // toast.dismiss();

    const { setHTML } = useHTMLContext();
    const { setHtmlResponse } = useApi();
    const { updateSelectedVideo } = useContext(VideoContext);
    const [isOpen, setOpen] = useState(false);
    const { setDisplayCreateButton, addNewButton } = useNavbarContext();
    useEffect(() => {
        setHtmlResponse('');
        updateSelectedVideo(null);
        setHTML('');
        setDisplayCreateButton(true);
        toast.dismiss();
        addNewButton([
            { name: 'Create a video chat', link: '/story-creation' },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDisplayCreateButton]);

    return (
        <div className='Main_Wrapper'>
            <Navbar isAdmin={false} sendVideo={false} />
            <div className={`Content_Wrapper ${isOpen ? 'Content_Wrapper_open' : ''}`}>
                <SideBar isAdmin={false} isOpen={isOpen} setOpen={setOpen} />
                <div className="Main_Content_box ">
                    <div className='Content_box_inner '>
                        {localStorage.getItem("tippen_access_token") && !localStorage.getItem("is_admin") ? <Outlet /> :
                            localStorage.getItem("tippen_access_token") && localStorage.getItem("is_admin") ? <Navigate to="/admin" /> :
                                <Navigate to="/" />}
                    </div>
                </div>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    gutter={8}
                    containerClassName=""
                    containerStyle={{}}
                    toastOptions={{
                        className: '',
                        duration: 5000,
                        style: {
                            background: 'rgb(255 199 2)',
                            color: '#fff',
                        },
                        success: {
                            duration: 3000,
                            theme: {
                                primary: 'green',
                                secondary: 'black',
                            },
                        },
                    }} />
            </div>
        </div>
    )
}

export default PrivateRoute
