
import Image from '../images/defaultScreenBackground.jpg'
export const styles = {
    backgroundImageStyle: {
        backgroundImage: `url(${Image})`,
        backgroundColor: 'rgba(0, 0, 0)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '375px',
        height: '675px',
        borderRadius: '20px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        overflow: 'hidden',
    },
    FormStyle: {
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
        borderRadius: '20px',
        transform: 'translate(0,100%)',
        transition: '.4s',
        padding: '60px 20px',
        overflowY: 'auto'
    },
    contentStyle: {
        display: 'inline-block',
        cursor: 'pointer',
        margin: '0',
        textAlign: 'center',
        fontSize: 'inherit',
        zIndex: '99',
        wordBreak: 'break-all',
        fontWeight: '500',
    }, ButtonStyle: {
        height: '40px',
        padding: ' 8px 24px',
        borderRadius: '40px',
        border: '0',
        background: 'linear-gradient(114.44deg, #7433FF 0%, #FFA3FD 100%)',
        display: 'inline-flex',
        cursor: 'pointer',
        justifyContent: 'center',
        wordBreak: 'break-all',
        overflow: 'hidden',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'white',
        zIndex: '99',
        minWidth: '218px',
        width: 'auto',
        maxWidth: '330px',

    }, videoScreenStyle: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        width: '375px',
        height: '675px',
        borderRadius: '20px',
        boxShadow: '0px 4px 4px 4px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        overflow: 'hidden',
        objectFit: 'cover',
        zIndex: '9'
    },
    StoryContentStyle: {
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '400',
        position: 'absolute',
        bottom: '0',
        right: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        gap: '15px',
        padding: '10px 10px 20px',
        height: '100%',
        alignItems: 'center',
        width: '100%',
        left: '0'
    },
    BackbuttonDiv: {
        position: 'absolute',
        top: '13px',
        left: '20px',
        fontSize: '26px'
    },
    BackbuttonDiv1: {
        position: 'absolute',
        top: '13px',
        left: '20px',
        fontSize: '26px',
        zIndex: '99',
        display: 'block'
    },
    tippen_videoNameInputLabel: {
        display: 'block',
        marginBottom: '13px',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: '400',
    },
    videoNameInput: {
        width: '100%',
        height: '56px',
        padding: ' 8px 24px',
        borderRadius: '16px',
        backgroundColor: ' rgba(239, 240, 246, 1)',
        color: 'rgba(78, 75, 102, 1)',
        border: 'none',
    },
    closeFormButton: {
        backgroundImage: ' none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        fontFamily: 'inherit',
        fontFeatureSttings: 'inherit',
        fontVariationSettings: 'inherit',
        fontSize: '100%',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        color: 'inherit',
        margin: '0',
        padding: '0',
        border: 'none',
    },
    FaFaIcon: {
        display: 'none',
        position: ' absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '30px',
        zIndex: '8',
        filter: 'drop-shadow(0 0 5px rgba(0, 0, 0, 0.5))',
        backgroundImage: 'linear-gradient(transparent, rgba(0, 0, 0, 0.6))',
        transition: 'all 250ms ease 0s',
    },
    FaFaIconMuteUnmute: {
        display: 'block',
        position: ' absolute',
        top: '12px',
        right: '12px',
        zIndex: '99',
    }
};
