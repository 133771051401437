import React from 'react'
import '../assets/css/style.css'
const Pricing = () => {
    return (
        <>
            <section className="pricing-section fadeIn">
                <div className="container">
                    <div className="sec-title text-center">
                        <h2>Choose a Plan</h2>
                    </div>

                    <div className="outer-box">
                        <div className="pricing_outer_div">
                            <div className="pricing-block wow fadeInUp" data-wow-delay="400ms">
                                <div className="inner-box">
                                    <div className="icon-box">
                                        <div className="icon-outer"><i className="fas fa-gem"></i></div>
                                    </div>
                                    <div className="price-box">
                                        <div className="title">Inbound</div>
                                        <h4 className="price">$100 per month</h4>
                                    </div>
                                    <ul className="features">
                                        <li className="true">Up to 2 short videos
                                        </li>
                                        <li className="true">
                                            Schedule calls with Calendly integration
                                        </li>
                                        <hr/>
                                        <hr/>
                                        <li className="coming_soon">
                                            Coming Soon
                                        </li>
                                        <li className="waiting">
                                            Video Quizzes
                                        </li>
                                        <li className="waiting">
                                            Video Meeting Scheduling
                                        </li>
                                        <li className="waiting">
                                            Video & Audio chat
                                        </li>
                                        <li className="waiting">
                                            Sales conversation with Slack integration
                                        </li>
                                        <li className="waiting">
                                            Person-level website visitor identity
                                        </li>
                                        <li className="waiting">
                                            LinkedIn Profiles Push to Slack
                                        </li>
                                        <li className="waiting">
                                            Pageview History
                                        </li>
                                        <li className="waiting">
                                            Repeat Visitors
                                        </li>
                                        <li className="waiting">
                                            ICP Filtering
                                        </li>

                                    </ul>
                                    <div className="btn-box">
                                        <a href="#" className="theme-btn">Pay now</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing