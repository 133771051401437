/* eslint-disable no-undef */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHTMLContext } from '../../Context/HtmlContext';
import { Link, useNavigate } from 'react-router-dom';
import mainPeople from '../../assets/images/avatar.jpg';
import { styles } from '../../assets/js/style';
import ProfileContext from '../../Context/ProfileContext';
import { VideoContext } from '../../Context/VideoContext';
import Loader from '../Loader';
import { useDragContext } from '../../Context/DragContext';
import { useApi } from '../../Context/ApiContext';
const Preview = () => {
  const { videoUrl, sendHtmlToAnotherApi, htmlResponse, loading, setLoading, setVideoUrl } = useApi();
  const { htmlContent } = useHTMLContext();
  const { updateSelectedVideo, selectedVideo, settings, setSettings, screen2Settings, setScreen2Settings } = useContext(VideoContext);
  const [secondVedio, setSecondVedio] = useState(false);
  const { id, videoName, title, subTitle, buttonText, receiverEmail, videoCover, WebUrl, actionType, contact_us_button_text, buttonTextUrl } = settings;
  const { profilePhoto } = useContext(ProfileContext);
  const [videoUrlReceived, setVideoUrlReceived] = useState(false);
  const titleRef = useRef(null);
  const subTitleRef = useRef(null);
  const buttonRef = useRef(null);
  const titleRef1 = useRef(null);
  const subTitleRef1 = useRef(null);
  const buttonRef1 = useRef(null);
  const button2Ref = useRef(null);
  const mobileScreensRef = useRef(null);
  // const formContainerRef = useRef(null);
  const FaFaRef = useRef(null);
  const { dragValues } = useDragContext();
  // const [formOpen, setFormOpen] = useState(false);
  const [muted, setMuted] = useState(false);
  const MuteUnmute = () => {
    setMuted(!muted);
  };
  // const openForm = () => {
  //   formContainerRef.current.style.transform = 'translate(0, 0)';
  //   formContainerRef.current.style.zIndex = '999';
  //   var elem = document.getElementById("tippne_mobile_Screen_id");
  //   elem.scrollIntoView();
  //   setFormOpen(true);
  // };

  // const closeForm = () => {
  //   formContainerRef.current.style.transform = 'translate(0, 100%)';
  //   formContainerRef.current.style.zIndex = '0';
  //   setFormOpen(false);
  // };

  const PlayPause = () => {
    var myVideo = document.getElementById('tippne_screen_video_id');
    if (myVideo.paused) {
      myVideo.play();
      FaFaRef.current.classList.add('tippen_fadePauseOut');
      FaFaRef.current.style.zIndex = '8';
      setTimeout(() => {
        FaFaRef.current.style.display = 'none';
      }, 300);
    } else {
      FaFaRef.current.style.display = 'flex';
      FaFaRef.current.classList.remove('tippen_fadePauseOut');
      FaFaRef.current.style.zIndex = '9999';
      FaFaRef.current.classList.add('tippen_fadeIn');
      myVideo.pause();
    }

  };
  let navigate = useNavigate();

  useEffect(() => {
    if (htmlResponse) {
      setLoading(false);
      updateSelectedVideo(null);
      setVideoUrl(null);
      setSettings({
        videoName: 'Video Title',
        title: 'Title',
        subTitle: 'Sub Title',
        buttonText: 'Help',
        contact_us_button_text: 'Contact us',
        receiverEmail: '',
        actionType: 'openUrl',
        WebUrl: '',
        videoCover: '',
      });
      setScreen2Settings({
        title: 'Title',
        subTitle: 'Sub Title',
        contact_us_button_text: 'Contact us',
      });
      navigate('/publish');
    }
    else if (videoUrl) {
      updateSelectedVideo(videoUrl.video1);
      setVideoUrlReceived(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl, htmlResponse]);

  useEffect(() => {
    if (videoUrlReceived) {
      // closeForm();
      let secondVideo = '';
      if (actionType === 'nextVideo') {
        secondVideo = videoUrl.video2;
      }
      setSecondVedio(false);
      const videoElement = document.getElementById('tippne_screen_video_id');
      const tippne_clickeventButton = document.getElementById('tippne_clickeventButton');
      const tippne_VideobackButtonDiv = document.getElementById('tippne_VideobackButtonDiv');
      const tippne_story_content_id_1 = document.getElementById('tippne_story_content_id_1');
      const tippne_story_content_id = document.getElementById('tippne_story_content_id_2');
      if (tippne_clickeventButton) {
        tippne_clickeventButton.style.display = 'block';
      }
      if (tippne_VideobackButtonDiv) {
        tippne_VideobackButtonDiv.style.display = 'none';
      }
      if (tippne_story_content_id_1) {
        console.log('sadsa');
        tippne_story_content_id_1.style.display = 'flex';
      }
      if (tippne_story_content_id) {
        tippne_story_content_id.style.display = 'none';
      }
      videoElement.src = selectedVideo;
      videoElement.play();
      const htmlContent = mobileScreensRef.current.innerHTML;
      const baseUrl = process.env.REACT_APP_BASE_URL + 'send-email';
      let script1
      let script2 = '';
      if (actionType === 'openUrl') {
        script1 = `window.open('${WebUrl}', '_blank');`
      } else if (actionType === 'nextVideo') {
        script1 = `const videoElement = document.getElementById('tippne_screen_video_id');
        const tippne_clickeventButton = document.getElementById('tippne_clickeventButton');
        const tippne_VideobackButtonDiv = document.getElementById('tippne_VideobackButtonDiv');
        const content1 = document.getElementById('tippne_story_content_id_1');
    const content2 = document.getElementById('tippne_story_content_id_2');
    
    if (tippne_clickeventButton) {
      tippne_clickeventButton.style.display = 'none';
      content1.style.display = 'none';
    }
    if (tippne_VideobackButtonDiv) {
      tippne_VideobackButtonDiv.style.display = 'block';
      content2.style.display = 'flex';
    }
        videoElement.src = '${secondVideo}';
        videoElement.play();`
        script2 = `
        document.getElementById('tippne_FaFaVideobackButton').addEventListener('click', previousVedio);
         function previousVedio(){
          const videoElement = document.getElementById('tippne_screen_video_id');
          const content1 = document.getElementById('tippne_story_content_id_1');
          const content2 = document.getElementById('tippne_story_content_id_2');
          const tippne_clickeventButton = document.getElementById('tippne_clickeventButton');
          const tippne_VideobackButtonDiv = document.getElementById('tippne_VideobackButtonDiv');
          if (tippne_clickeventButton) {
            tippne_clickeventButton.style.display = 'block';
            content1.style.display = 'flex';
          }
          if (tippne_VideobackButtonDiv) {
            tippne_VideobackButtonDiv.style.display = 'none';
            content2.style.display = 'none';
          }
        videoElement.src = '${selectedVideo}';
        videoElement.play();
         }
        `
      }
      const script = `
      
          <script src="https://code.jquery.com/jquery-3.7.1.min.js" integrity="sha256-/JqT3SQfawRcv/BIHPThkBvs0OEvtFFmqPF/lYI/Cxo=" crossorigin="anonymous"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.js" integrity="sha512-VEd+nq25CkR676O+pLBnDW09R7VQX9Mdiij052gVCp5yVH3jGtH70Ho/UUv4mJDsEdTvqRCFZg0NKGiojGnUCw==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs" type="module"></script>
          <script>
        
          function TippenButtonClickEvent(){
${script1}
            }
            ${script2}
            function tippen_openForm() {
              var elem = document.getElementById("tippne_mobile_Screen_id");
              elem.scrollIntoView();
              document.querySelector('.tippne_form-container').style.transform = 'translate(0, 0)';
              document.querySelector('.tippne_form-container').style.zIndex = '999';
          }
          function tippen_closeForm() {
              document.querySelector('.tippne_form-container').style.transform = 'translate(0, 100%)';
              document.querySelector('.tippne_form-container').style.zIndex = '0';
          }
          function tippen_playPauseVideo() {
              var myVideo = document.getElementById('tippne_screen_video_id');
              var FaFaRef = document.getElementById('tippne_faFaIConID');
              if (myVideo.paused) {
                  myVideo.play();
                  FaFaRef.classList.add('tippen_fadePauseOut');
                  FaFaRef.style.zIndex = '8';
                  setTimeout(() => {
                      FaFaRef.style.display = 'none';
                  }, 300);
              } else {
                  FaFaRef.style.display = 'flex';
                  FaFaRef.classList.remove('tippen_fadePauseOut');
                  FaFaRef.style.zIndex = '9999';
                  FaFaRef.classList.add('tippen_fadeIn');
                  myVideo.pause();
              }
          }
          function tippen_MuteUnmute() {
              const myVideo = document.getElementById('tippne_screen_video_id');
              const muteButton = document.getElementById('tippne_fafaMuteButton');
              const unmuteButton = document.getElementById('tippne_fafaUnmuteButton');
          
              if (myVideo.muted) {
                  myVideo.muted = false;
                  muteButton.style.display = 'none';
                  unmuteButton.style.display = 'block';
              } else {
                  myVideo.muted = true;
                  muteButton.style.display = 'block';
                  unmuteButton.style.display = 'none';
              }
          };
          // document.getElementById('tippne_open-button').addEventListener('click', tippen_openForm);
          // document.getElementById('tippne_open-button-1').addEventListener('click', tippen_openForm);
          // document.getElementById('tippen_close-button').addEventListener('click', tippen_closeForm);
          document.getElementById('tippne_screen_video_id').addEventListener('click', tippen_playPauseVideo);
          document.getElementById('tippne_fafaUnmuteButton').addEventListener('click', tippen_MuteUnmute);
          document.getElementById('tippne_fafaMuteButton').addEventListener('click', tippen_MuteUnmute);
          document.getElementById('tippne_faFaIConID').addEventListener('click', tippen_playPauseVideo);
          
         document.getElementById('tippne_clickeventButton').addEventListener('click', TippenButtonClickEvent);
          window.onload = function() {
            var myVideo = document.getElementById('tippne_screen_video_id');
            myVideo.muted = true;
            if (myVideo.paused) {
                myVideo.click();
            }
        };
        var tippenSubmitButton = document.getElementById("tippen_submitButton");
        if (tippenSubmitButton) {
              tippenSubmitButton.addEventListener("click", function () {
                  submitAjaxForm();
              });
        }
        var tippenSenderEmail = document.getElementById("tippen_senderEmail");
        if (tippenSenderEmail) {
              tippenSenderEmail.addEventListener("click", function () {
                if (e.key === 'Enter') {
                      e.preventDefault();
                      submitAjaxForm()
                    }
              });
         }
        // function submitAjaxForm() {
        //   document.getElementById("tippen_errorSpanId").innerHTML  = ' ';
        //       document.getElementById("dotlottie_id_1").style.display = 'block';
        //             document.getElementById("dotlottie_id_1").play();
        //   var receiverEmail = document.getElementById("tippen_receiverEmail").value;
        //   var tippen_senderEmail = document.getElementById("tippen_senderEmail").value;
        //   var xhr = new XMLHttpRequest();
        //   xhr.open("POST", '${baseUrl}', true);
        //   xhr.setRequestHeader("Content-Type", "application/json");
        //   var emailData = {
        //     to: receiverEmail,
        //     email: tippen_senderEmail,
        //     html_id:'${videoUrl.html_id}',
        //   };
        //   var jsonData = JSON.stringify(emailData);
        //   xhr.onload = function () {
        //     if (xhr.status >= 200 && xhr.status < 300) {
        //       document.getElementById("dotlottie_id_1").currentTime = 0;;
        //             document.getElementById("dotlottie_id_1").style.display = 'none';

        //       const response = JSON.parse(xhr.responseText);
        //       if (response.status == 200) {
        //         document.getElementById("tippen_senderEmail").value = '';
        //         if (Array.isArray(response.message)) {
        //           response.message.forEach(message => {
        //             document.getElementById("tippen_senderEmail").value = '';
        //             document.getElementById("dotlottie_id").style.display = 'block';
        //             document.getElementById("dotlottie_id").play();
        //             setTimeout(() => {
        //               document.getElementById("dotlottie_id").currentTime = 0;;
        //               document.getElementById("dotlottie_id").style.display = 'none';
        //               tippen_closeForm();
        //             }, 1500);
        //             document.getElementById("tippen_errorSpanId").innerHTML = ' ';
        //           });
        //         }
        //       } else {
        //         document.getElementById("tippen_errorSpanId").innerHTML += '<p>' + response.message[0] +
        //           '</p>';
        //       }
        //     } else {
        //        document.getElementById("dotlottie_id_1").currentTime = 0;;
        //             document.getElementById("dotlottie_id_1").style.display = 'none';
        //       const response = JSON.parse(xhr.responseText);
        //       if (Array.isArray(response.message)) {
        //           document.getElementById("tippen_errorSpanId").innerHTML += '<p>' + response.message[0] +
        //             '</p>';
        //       } else {
        //         document.getElementById("tippen_errorSpanId").innerHTML += '<p>Failed to send email.</p>';
        //       }
        //     }
        //   };
        //   xhr.send(jsonData);
        // }
             window.addEventListener('message', function(event) {
        // Check the origin of the message to ensure it's from a trusted source
      
        var video = document.getElementById('tippne_screen_video_id');
        var FaFaRef = document.getElementById('tippne_faFaIConID');
        if (event.data === 'play') {
            video.play();
            FaFaRef.classList.add('tippen_fadePauseOut');
            FaFaRef.style.zIndex = '8';
            setTimeout(() => {
                FaFaRef.style.display = 'none';
            }, 300);
        } else if (event.data === 'pause') {
          FaFaRef.style.display = 'flex';
          FaFaRef.classList.remove('tippen_fadePauseOut');
          FaFaRef.style.zIndex = '9999';
          FaFaRef.classList.add('tippen_fadeIn');
            video.pause();
        }
          
    }, false);
      </script>
          `;

      const html = `
              <!DOCTYPE html>
              <html lang="en" style='height:100%'>

              <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>Document</title>
                  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                  <link
                      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                      rel="stylesheet">
                      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.css" integrity="sha512-3pIirOrwegjM6erE5gPSwkUzO+3cTjpnV9lexlNZqvupR64iZBnOOTiiLPb9M36zpMScbmUNIcHUqKD47M719g==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                      <style>
                      * {
                          box-sizing: border-box;
                      }
                      .tippen_fadeIn {
                        animation-name: tippen_fadeIn;
                        animation-duration: .8s;
                        animation-timing-function: ease-out;
                        z-index: 1001;
                    }
                    
                    .tippen_fadePauseOut {
                        opacity: 0 !important;
                        transition: opacity 0.3s ease !important;
                    }
                    

@keyframes tippen_fadeIn {
  0% {

      opacity: 0;
  }

  100% {

      opacity: 1;
  }
}

@media screen and (max-device-width: 768px) {


  .tippne_mobile_screens .tippne_screen_video {
      width: 100% !important;
      height: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .tippne_mobile_screens, .tippne_screen_video{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

}

#dotlottie_id {
  position: absolute;
  bottom: 10%;
  display: none;
}
#dotlottie_id_1 {
  position: absolute;
  bottom: 10%;
  display: none;
}

                      </style>
              </head>
              <body style="margin: 0; display:flex;justify-content:center;height:100%;padding:20px">
              ${htmlContent}
              </body>
               ${script}
              </html>
          `;

      sendHtmlToAnotherApi(id, html, receiverEmail, videoName);
      setVideoUrlReceived(false);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrlReceived, sendHtmlToAnotherApi]);
  useEffect(() => {
    const { title, subTitle, button, title1, subTitle1, button1, button2 } = dragValues;
    if (title && titleRef.current) {
      titleRef.current.style.transform = `translate(${title.x}px, ${title.y}px)`;
    }
    if (subTitle && subTitleRef.current) {
      subTitleRef.current.style.transform = `translate(${subTitle.x}px, ${subTitle.y}px)`;
    }
    if (button && buttonRef.current) {
      buttonRef.current.style.transform = `translate(${button.x}px, ${button.y}px)`;
    }
    console.log(buttonRef.current.style.transform);
    if (title1 && titleRef1.current) {
      titleRef1.current.style.transform = `translate(${title1.x}px, ${title1.y}px)`;
    }
    if (subTitle1 && subTitleRef1.current) {
      subTitleRef1.current.style.transform = `translate(${subTitle1.x}px, ${subTitle1.y}px)`;
    }

    if (button1 && buttonRef1.current) {
      buttonRef1.current.style.transform = `translate(${button1.x}px, ${button1.y}px)`;
    }
    if (button2 && button2Ref.current) {
      button2Ref.current.style.transform = `translate(${button2.x}px, ${button2.y}px)`;
    }
  }, [dragValues]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return 'Are you sure you want to leave? Your data may not be saved.';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  useEffect(() => {
    if (selectedVideo) {
      const executeScriptTags = (htmlContent) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        const scripts = tempElement.querySelectorAll('script');
        scripts.forEach((script) => {
          const newScript = document.createElement('script');
          newScript.textContent = script.textContent;
          document.body.appendChild(newScript);
        });
      };
      executeScriptTags(htmlContent);
      return () => {
        const dynamicScripts = document.querySelectorAll('script[data-dynamic]');
        dynamicScripts.forEach((script) => {
          script.parentNode.removeChild(script);
        });
      };
    }
  }, [htmlContent, selectedVideo]);
  const ButtonClickEvent = () => {
    if (actionType === 'openUrl') {
      window.open(WebUrl, '_blank');
    } else if (actionType === 'nextVideo') {
      setSecondVedio(true);

      const videoElement = document.getElementById('tippne_screen_video_id');
      const tippne_clickeventButton = document.getElementById('tippne_clickeventButton');
      const tippne_VideobackButtonDiv = document.getElementById('tippne_VideobackButtonDiv');
      if (tippne_clickeventButton) {
        tippne_clickeventButton.style.display = 'none';
      }
      if (tippne_VideobackButtonDiv) {
        tippne_VideobackButtonDiv.style.display = 'block';
      }
      videoElement.src = videoCover instanceof File ? URL.createObjectURL(videoCover) : videoCover;
      videoElement.play();
    }
  }

  const backButton = () => {
    if (actionType === 'nextVideo') {
      setSecondVedio(false);
      const videoElement = document.getElementById('tippne_screen_video_id');
      const tippne_clickeventButton = document.getElementById('tippne_clickeventButton');
      const tippne_VideobackButtonDiv = document.getElementById('tippne_VideobackButtonDiv');
      if (tippne_clickeventButton) {
        tippne_clickeventButton.style.display = 'block';
      }
      if (tippne_VideobackButtonDiv) {
        tippne_VideobackButtonDiv.style.display = 'none';
      }
      videoElement.src = selectedVideo instanceof File ? URL.createObjectURL(selectedVideo) : selectedVideo;
      videoElement.play();
    }
  }

  return (
    <div>
      {loading &&
        <div className='sendingHtmlLoader'><Loader /></div>
      }
      {selectedVideo ? (
        <div >
          <div className='Preview_Video_Main_Div'>
            <Link to='/story-creation'>
              <button className="create_video_button go_back_button">Go Back</button>
            </Link>
          </div>
          <section className='PreviewSection'>
            <div className='container'>
              <div className='main_preview'  >
                <div className='MPHead'>
                  <h3 className='MPHh3'>Preview</h3>
                  <div className='Story_name'>
                    {profilePhoto.profilePhoto ? <img className='SNimg' src={profilePhoto.profilePhoto} alt="userIcon" width='56' height='56' /> : <img className='SNimg' src={mainPeople} alt="userIcon" width='56' height='56' />}
                    <h3 className='SNh3'>{videoName}</h3>
                  </div>
                </div>
                <div className='main_preview_div_1' ref={mobileScreensRef} style={{ height: '675px', width: '375px' }}>
                  <div className='tippne_mobile_screens' id='tippne_mobile_Screen_id' style={{ ...styles.videoScreenStyle, height: '100%', width: '100%' }} >
                    <video onClick={PlayPause} className='tippne_screen_video' id='tippne_screen_video_id' style={{ ...styles.videoScreenStyle, height: '100%', width: '100%' }} autoPlay loop muted={muted}>
                      {selectedVideo instanceof File ? (
                        <source src={URL.createObjectURL(selectedVideo)} type='video/mp4' />
                      ) : (
                        <source src={selectedVideo} type='video/mp4' />
                      )}
                      Your browser does not support the video tag.
                    </video>
                    <div onClick={PlayPause} id='tippne_faFaIConID' style={styles.FaFaIcon} ref={FaFaRef}>
                      <i style={{ zIndex: '9999', fontSize: '70px', color: '#fff' }} className="fa fa-play-circle"></i>
                    </div>
                    {videoUrlReceived ? (
                      <div id='tippne_faFaMuteIConID' style={styles.FaFaIconMuteUnmute}>
                        <i onClick={MuteUnmute} style={{ zIndex: '99', fontSize: '20px', color: '#fff', display: 'block' }} id='tippne_fafaMuteButton' className='fas fa-volume-mute'></i>
                        <i onClick={MuteUnmute} style={{ zIndex: '99', fontSize: '20px', color: '#fff', display: 'none' }} id='tippne_fafaUnmuteButton' className='fas fa-volume-up'></i>
                      </div>

                    ) : (
                      <div id='tippne_faFaMuteIConID' style={styles.FaFaIconMuteUnmute}>
                        <i onClick={MuteUnmute} style={{ zIndex: '99', fontSize: '20px', color: '#fff', display: muted ? 'block' : 'none' }} id='tippne_fafaMuteButton' className='fas fa-volume-mute'></i>
                        <i onClick={MuteUnmute} style={{ zIndex: '99', fontSize: '20px', color: '#fff', display: muted ? 'none' : 'block' }} id='tippne_fafaUnmuteButton' className='fas fa-volume-up'></i>
                      </div>
                    )}
                    <div className='tippne_story_content' id="tippne_story_content_id_1" style={{ ...styles.StoryContentStyle, display: secondVedio ? 'none' : 'flex' }}>
                      <h1 style={{ ...styles.contentStyle, fontSize: '20px' }} draggable='false' ref={titleRef}>{title}</h1>
                      <p style={styles.contentStyle} draggable='false' ref={subTitleRef}>{subTitle}</p>
                      <a href={buttonTextUrl} style={{ ...styles.ButtonStyle, transform: `translate(${dragValues.button.x}px, ${dragValues.button.y}px` }} draggable='false' target='blank' className='tippne_screen_clickablebutton' ref={buttonRef} id='tippne_open-button'>{contact_us_button_text}</a>
                      <button onClick={ButtonClickEvent} style={styles.ButtonStyle} draggable='false' target='blank' className='tippne_screen_clickablebutton' ref={button2Ref} id='tippne_clickeventButton'>{buttonText}</button>
                    </div>
                    <div id='tippne_VideobackButtonDiv' style={{ ...styles.BackbuttonDiv1, display: secondVedio ? 'block' : 'none' }}>
                      <button type='button' id='tippne_FaFaVideobackButton' style={styles.closeFormButton} onClick={backButton} ><i className="fa fa-angle-left" style={{ color: 'white ' }}></i></button>
                    </div>
                    <div className='tippne_story_content' id="tippne_story_content_id_2" style={{ ...styles.StoryContentStyle, display: secondVedio ? 'flex' : 'none' }}>
                      <h1 style={{ ...styles.contentStyle, fontSize: '20px' }} draggable='false' ref={titleRef1}>{screen2Settings.title}</h1>

                      <p style={styles.contentStyle} draggable='false' ref={subTitleRef1}>{screen2Settings.subTitle}</p>

                      <a href={buttonTextUrl} style={styles.ButtonStyle} draggable='false' target='blank' className='tippne_screen_clickablebutton' ref={buttonRef} id='tippne_open-button'>{contact_us_button_text}</a>

                    </div>
                    {/* <div style={styles.FormStyle} ref={formContainerRef} className={`tippne_form-container ${formOpen ? 'tippen_open' : ''}`}>
                      <div className='' style={styles.BackbuttonDiv}>
                        <button type='button' id='tippen_close-button' style={styles.closeFormButton} onClick={closeForm} ><i className="fa fa-angle-left"></i></button>
                      </div>
                      <h2 style={{ textAlign: 'center', fontSize: '20px', fontWeight: '700', marginBottom: '34px' }}>Fill your information</h2>
                      <div className='' style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center'
                      }}>
                        < div className='mb-5' style={{ width: '100%', marginBottom: ' 1.25rem', }} >
                          <label className='tippen_videoNameInputLabel' style={styles.tippen_videoNameInputLabel} htmlFor='#tippen_senderEmail'>Your Email</label>
                          <input style={styles.videoNameInput}
                            type='email'
                            id='tippen_senderEmail'
                            className='outline-none videoNameInput tippen_senderEmailInput'
                            placeholder='Your email'
                          />
                          <span id="tippen_errorSpanId" style={{ color: 'red' }}></span>

                        </div>
                        <input type='hidden' value={receiverEmail} id='tippen_receiverEmail' />
                        <button id='tippen_submitButton' style={styles.ButtonStyle}>Submit</button>
                        <dotlottie-player id="dotlottie_id" loop
                          src="https://lottie.host/546cb22e-f131-4d41-87c1-380b22e3d02f/XiRFGSbWIC.json" background="transparent"
                          speed="1" style={{ width: '160px', height: '160px' }}></dotlottie-player>
                        <dotlottie-player id="dotlottie_id_1" loop
                          src="https://lottie.host/175f7a31-8791-4c44-b121-7677f5e19419/tjcz9CWfMc.json" background="transparent"
                          speed="1" style={{ width: '160px', height: '160px' }}></dotlottie-player>
                      </div>
                    </div> */}
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      ) : (
        navigate(-1)
      )}

    </div>
  );
};

export default Preview;
