import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const HTMLContext = createContext();
export const HTMLProvider = ({ children }) => {
    const [htmlContent, setHtmlContent] = useState('');

    const setHTML = (content) => {
        setHtmlContent(content);
    };

    return (
        <HTMLContext.Provider value={{ htmlContent, setHTML }}>
            {children}
        </HTMLContext.Provider>
    );
};

HTMLProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useHTMLContext = () => useContext(HTMLContext);
