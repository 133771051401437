import React from 'react';
import Bag from '../assets/images/Tippen_icon/Bag.svg';
import Image from '../assets/images/Tippen_icon/Image.svg';
// import Play from '../assets/images/Tippen_icon/Play.svg';
import Profile from '../assets/images/Tippen_icon/Profile.svg';
import Setting from '../assets/images/Tippen_icon/Settings.svg';
import Hamburger from 'hamburger-react'
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
const SideBar = ({ isAdmin, isOpen, setOpen }) => {
    const adminSidebarItems = [
        { icon: Profile, text: 'Users', link: '/admin' },
        { icon: Setting, text: 'Settings', link: '/admin-setting' },
    ];


    const userSidebarItems = [
        { icon: Image, text: 'Media Library', link: '/media-library' },
        // { icon: Play, text: 'On-site video', link: '/' },
        { icon: Bag, text: 'Pricing', link: '/pricing' },
        { icon: Setting, text: 'Settings', link: '/setting' },
        { icon: Profile, text: 'Support', link: '/support' },
    ];

    const sidebarItems = isAdmin ? adminSidebarItems : userSidebarItems;
    const closeNavbar = () => {
        setOpen(false);
    }
    return (
        <div className={`Side_Navbar ${isOpen ? 'Side_Navbar_open' : ''}`}>
            <span className='hamburger_span'>
                <Hamburger toggled={isOpen} toggle={setOpen} />
            </span>
            <ul className='Sidebar_ul'>
                {sidebarItems.map((item, index) => (
                    <li key={index} className='S_ul_li'>
                        <NavLink to={item.link} activeclassname="active" onClick={closeNavbar} >
                            <img src={item.icon} alt="" />
                            <span className={`span_item_data ${isOpen ? 'span_item_data_open' : ''}`}>
                                {item.text}
                            </span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
SideBar.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default SideBar;
