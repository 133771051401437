import Preview from '../components/Video/Preview';

import React, { useEffect } from 'react'

import { useNavbarContext } from '../Context/NavbarContext';
const StoryPreview = () => {
    const { addNewButton } = useNavbarContext();

    useEffect(() => {
        addNewButton([
            { name: 'Edit Video', link: '/story-creation', back: true },
            { name: 'Publish', link: '/publish', back: false },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className=' fadeIn'>
            <Preview />
        </div>
    )
}

export default StoryPreview