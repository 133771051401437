import React, { useContext, useState, useCallback, useEffect } from 'react';
import axiosBaseURL from '../baseUrl';
import toast from 'react-hot-toast';
import ProfileContext from '../Context/ProfileContext';

import mainPeople from '../assets/images/avatar.jpg';
const Setting = () => {
  const { profilePhoto, setProfilePhotoUrl } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [data, setData] = useState({ name: profilePhoto.name, email: profilePhoto.email });
  useEffect(() => {
    setData({
      name: profilePhoto.name, email: profilePhoto.email
    });
  }, [profilePhoto.name, profilePhoto.email])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
    else {
      setSelectedImage(false);
    }
  };

  const imageStyle = {
    width: '100%',
    maxWidth: '150px',
    height: '100%',
    maxHeight: '150px',
    border: ' 1px solid #ccc',
    boxShadow: '0px 3px 8px #ccc',
    borderRadius: '5px',
    padding: '4px',
    display: 'block',
    objectFit: 'contain'
  }
  const updateUserData = useCallback((data, e) => {
    if (loading) return;
    setLoading(true);
    const file = e.target.elements.file.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      setLoading(false);
      return Promise.reject(new Error("Image size should be less than 2MB"));
    }
    if (file && !file.type.startsWith('image/')) {
      setLoading(false);
      return Promise.reject(new Error("Please select an image file."));
    }
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('file', file);
    return axiosBaseURL.post("/update-users", formData, {}).finally(() => {
      setLoading(false);
    });
  }, [loading]);

  const UpdateData = (e) => {
    e.preventDefault();
    toast.dismiss()
    toast.promise(
      updateUserData(data, e),
      {
        loading: 'Loading',
        success: (response) => {
          if (response.data.status === 200) {
            if (response.data.result.profile_photo) {
              const { profile_photo, name, email } = response.data.result;
              setProfilePhotoUrl({
                profilePhoto: profile_photo,
                name,
                email
              });
            }
            setData({
              'name': response.data.result.name,
              'email': response.data.result.email
            })
            const message = response.data.message.join('\n');
            return message;
          }
          else {
            const errorMessage = response.data.message.join('\n');
            throw new Error(errorMessage);
          }
        },
        error: (error) => {
          return error.message
        }
      }
    );
  }
  const handleKeyDown = (e) => {
    if (e.key === " " && !e.target.value.trim()) {
      e.preventDefault();
    }
  };
  return (
    <div>
      <form className="w-full" onSubmit={UpdateData}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
              Name
            </label>
            <input
              value={data.name}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              name='name'
              type="text"
              placeholder="Jane"
            />
          </div>
          <div className="w-full md:w-1/2  px-3 mb-6 ">
            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-city">
              Email
            </label>
            <input
              value={data.email}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-city"
              name='email'
              type="text"
              placeholder="abc@mail.com"
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full  px-3 mb-6 md:mb-0">
            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-city">
              Image
            </label>
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <div className="form-group">
                    <div className="row btn-file ">
                      <div className="btn-file__actions flex gap-6 justify-center align-center">
                        <label htmlFor="fileField" className="attachment">
                          <div className="btn-file__actions__item col-xs-12 text-center ">
                            <div className="btn-file__actions__item--shadow">
                              <i className="fa fa-plus fa-lg fa-fw" aria-hidden="true"></i>
                              <div className="visible-xs-block"></div>
                              Select file
                            </div>
                          </div>
                          <input name="file" type="file" onChange={handleImageChange} id="fileField" accept="image/*" />
                        </label>
                        <div className="w-1/2 flex justify-center">
                          {selectedImage ? (
                            <img src={selectedImage} alt="Selected Image" style={imageStyle} />
                          ) : (
                            profilePhoto.profilePhoto ? (
                              <img src={profilePhoto.profilePhoto} style={imageStyle} alt="userIcon" />
                            ) : (
                              <img src={mainPeople} alt="userIcon" style={imageStyle} />
                            )
                          )}

                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2 py-6 flex items-center justify-center">
          <button type={loading ? 'button' : 'submit'} style={{ display: 'block' }} className="w-full md:w-1/4 500 text-white block py-3 rounded-xl create_video_button_1">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default Setting;
